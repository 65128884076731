import * as Sentry from "@sentry/vue";
import { ref, watch } from "vue";
import { defineStore } from "pinia";
import { DateTime } from "luxon";
import { useSearchParamsStore } from "./searchParams";
import {
  ActiveSession,
  ActiveSessionsResponse,
  CalendarEventsResponse,
  CreateRoomResponse,
  CurrencyCode,
  MeetingRepeatability,
  OfferType,
  OffersResponse,
  ProfileResponse,
  Room,
  RoomsResponse,
  TodayCalendarEventsResponse,
} from "~/types";

type ActiveTab = "HOME" | "MY_ROOMS";

type FlatCalendarEvent = {
  id: string;
  meetingId: string;
  roomId: string;
  repeatability: MeetingRepeatability;
  name: string;
  startDateTime: string;
  endDateTime: string;
  roomName: string;
  roomPrivateUrl: string;
  roomType: "video" | "audio" | "streamed";
  owner: {
    id: string;
    username: string;
    name: string;
    avatarUrl?: string | null;
  };
};

type DrawerState =
  | { status: "INITIAL" }
  | { status: "INSTANT_MEETING_OPEN" }
  | { status: "SCHEDULE_WITH_ROOM_PICKER_OPEN" }
  | { status: "SCHEDULE_OPEN"; id: string; maxDurationInSec: number }
  | {
      status: "CALENDAR_EVENT_MORE_OPEN";
      calendarEvent: FlatCalendarEvent & { enableEdit: boolean };
    }
  | {
      status: "ENTER_ROOM_OPEN";
      room: Room & { session: ActiveSession | null };
    }
  | {
      status: "ROOM_SETTINGS_OPEN";
      id: string;
    }
  | {
      status: "ROOM_REMOVE_OPEN";
      id: string;
    }
  | {
      status: "MEETING_INVITE_USERS_OPEN";
      id: string;
    }
  | {
      status: "MEETING_EDIT_OPEN";
      meetingId: string;
      name: string;
      repeatability: MeetingRepeatability;
      roomId: string;
      startDatetime: string;
      endDatetime: string;
    };

type DeleteDrawerState =
  | {
      status: "INITIAL";
    }
  | {
      status: "DELETE_MEETING_OPEN";
      meetingId: string;
      calendarEventId: string;
      enableOwnerDelete: boolean;
    };

type AllCalendarEventsState = {
  status: "LOADED" | "LOADING" | "INITIAL";
  nextToken: string;
  data: Map<string, FlatCalendarEvent>;
  grouped: Map<string, FlatCalendarEvent[]>;
};

type TodayCalendarEventsState = {
  status: "LOADED" | "LOADING" | "INITIAL";
  data: Map<string, FlatCalendarEvent>;
};

export const getDateString = (date: string) => {
  const d = DateTime.fromISO(date, {
    zone: "utc",
  }).toLocal();

  if (d.startOf("day").hasSame(DateTime.now().startOf("day"), "day")) {
    const relativeName = d.toRelativeCalendar() || "";
    return `${
      relativeName[0].toUpperCase() + relativeName?.slice(1)
    }, ${d.toFormat("d LLLL")}`;
  }
  if (d.startOf("year").hasSame(DateTime.now().startOf("year"), "year")) {
    return d.toFormat("cccc, d LLLL");
  }
  return d.toFormat("cccc, d LLLL yyyy");
};

export const useDashboardStore = defineStore("dashboard", () => {
  const runtimeConfig = useRuntimeConfig();
  const { searchParams: searchParamsState } = useSearchParamsStore();
  const mobileActiveTab = ref<ActiveTab>("HOME");

  const drawerState = ref<DrawerState>({ status: "INITIAL" });

  const deleteDrawerState = ref<DeleteDrawerState>({ status: "INITIAL" });

  const allCalendarEvents = ref<AllCalendarEventsState>({
    status: "INITIAL",
    nextToken: "",
    data: new Map(),
    grouped: new Map(),
  });

  const userProfile = ref<ProfileResponse | null>(null);
  const todayCalendarEvents = ref<TodayCalendarEventsState>({
    status: "INITIAL",
    data: new Map(),
  });
  const activeSessions = ref<ActiveSession[]>([]);
  const rooms = ref<Room[]>([]);
  const roomOfferTypes = ref<OfferType[]>([]);

  const roomsWithSessions = computed(() => {
    const data = rooms.value.map((room) => {
      const session = activeSessions.value.find(
        (session) => session.room_id === room.id,
      );
      if (session) {
        return {
          ...room,
          session,
        };
      }
      return {
        ...room,
        session: null,
      };
    });
    return data;
  });

  const fetchUserProfile = async () => {
    return await useAuthRequest<ProfileResponse>(
      `${runtimeConfig.public.backendApiUrl}/users/profile`,
    ).then((res) => {
      userProfile.value = res.data.value;
    });
  };

  const fetchTodayCalendarEvents = async () => {
    todayCalendarEvents.value.status = "LOADING";
    return await useAuthRequest<TodayCalendarEventsResponse>(
      `${runtimeConfig.public.backendApiUrl}/meetings/calendar_events/today`,
    ).then((res) => {
      todayCalendarEvents.value.status = "LOADED";
      if (res.data.value.calendar_events.length > 0) {
        transformCalendarEventsResponse(res.data.value).forEach(
          (calendarEvent) => {
            todayCalendarEvents.value.data.set(calendarEvent.id, calendarEvent);
          },
        );
      }
    });
  };

  const fetchRooms = async () => {
    return await useAuthRequest<RoomsResponse>(
      `${runtimeConfig.public.backendApiUrl}/rooms`,
    ).then((res) => {
      rooms.value = res.data.value.rooms;
    });
  };

  const fetchActiveSessions = async () => {
    return await useAuthRequest<ActiveSessionsResponse>(
      `${runtimeConfig.public.backendApiUrl}/rooms/sessions/active`,
    ).then((res) => {
      activeSessions.value = res.data.value.sessions;
    });
  };

  const fetchRoomOfferTypes = async () => {
    return await useAuthRequest<OffersResponse>(
      `${runtimeConfig.public.backendApiUrl}/rooms/offers`,
    ).then((res) => {
      roomOfferTypes.value = res.data.value.offers_summaries;
    });
  };

  const handleCalendarEventDetailsOpen = (id: string) => {
    searchParamsState.meetingId = id;
  };
  const handleCalendarEventDetailsClose = () => {
    delete searchParamsState.meetingId;
  };

  const handleCalendarEventDetailsAllParticipantsOpen = (meetingId: string) => {
    searchParamsState.allParticipants = meetingId;
  };

  const handleCalendarEventDetailsAllParticipantsClose = () => {
    delete searchParamsState.allParticipants;
  };

  const handleAllCalendarEventsOpen = () => {
    searchParamsState.allMeetings = "open";
  };
  const handleAllCalendarEventsClose = () => {
    delete searchParamsState.allMeetings;
    clearAllCalendarEvents();
  };

  const handleSettingsOpen = () => {
    searchParamsState.settings = "open";
  };
  const handleSettingsClose = () => {
    delete searchParamsState.settings;
  };

  const setAllCalendarEventsFilter = (filter: "" | "owned") => {
    if (filter) {
      searchParamsState.allMeetingsFilter = filter;
      return;
    }
    delete searchParamsState.allMeetingsFilter;
  };

  const handleAddRoomOpen = () => {
    searchParamsState.addRoom = "open";
  };

  const handleAddRoomChooseRoomType = (type: "audio" | "video") => {
    searchParamsState.addRoom = type;
  };

  const handleAddRoomClose = () => {
    delete searchParamsState.addRoom;
  };

  const handleAddRoomChooseSubscriptionType = (type: "monthly" | "annual") => {
    searchParamsState.subscriptionType = type;
  };

  const handleAddRoomClearSubscriptionType = () => {
    delete searchParamsState.subscriptionType;
  };

  const handleChooseRoomOffer = (offerId: string) => {
    searchParamsState.offerId = offerId;
  };

  const handleClearRoomOffer = () => {
    delete searchParamsState.offerId;
  };

  const handleRenewOpen = (roomId: string) => {
    searchParamsState.renewRoomId = roomId;
  };

  const handleRenewClose = () => {
    delete searchParamsState.renewRoomId;
  };

  const handleCheckoutSubscriptionClose = () => {
    delete searchParamsState.checkoutSubscriptionId;
  };

  const handleDrawerClose = () => {
    drawerState.value.status = "INITIAL";
  };

  const handleInstantMeetingOpen = () => {
    drawerState.value.status = "INSTANT_MEETING_OPEN";
  };

  const handleScheduleWithRoomPickerOpen = () => {
    drawerState.value = { status: "SCHEDULE_WITH_ROOM_PICKER_OPEN" };
  };

  const handleScheduleOpen = (id: string, maxDurationInSec: number) => {
    drawerState.value = { status: "SCHEDULE_OPEN", id, maxDurationInSec };
  };

  const handleCalendarEventMoreOpen = (id: string, type: "TODAY" | "ALL") => {
    let calendarEvent;
    if (type === "ALL") {
      calendarEvent = allCalendarEvents.value.data.get(id);
    }
    if (type === "TODAY") {
      calendarEvent = todayCalendarEvents.value.data.get(id);
    }
    if (!calendarEvent) {
      return;
    }
    if (!userProfile.value) {
      return;
    }

    const enableEdit = userProfile.value.id === calendarEvent.owner.id;
    const data = { ...calendarEvent, enableEdit } as const;
    drawerState.value = {
      status: "CALENDAR_EVENT_MORE_OPEN",
      calendarEvent: data,
    };
  };

  const handleEnterRoomOpen = (id: string) => {
    const room = roomsWithSessions.value.find((room) => room.id === id);
    if (!room) {
      return;
    }
    drawerState.value = {
      status: "ENTER_ROOM_OPEN",
      room,
    };
  };

  const handleRoomSettingsOpen = (id: string) => {
    drawerState.value = {
      status: "ROOM_SETTINGS_OPEN",
      id,
    };
  };

  const handleRoomRemoveOpen = (id: string) => {
    drawerState.value = {
      status: "ROOM_REMOVE_OPEN",
      id,
    };
  };

  const handleMeetingInviteUsersOpen = (id: string) => {
    drawerState.value = { status: "MEETING_INVITE_USERS_OPEN", id };
  };

  const handleMeetingEditOpen = (props: {
    meetingId: string;
    name: string;
    roomId: string;
    repeatability: MeetingRepeatability;
    startDatetime: string;
    endDatetime: string;
  }) => {
    drawerState.value = {
      status: "MEETING_EDIT_OPEN",
      meetingId: props.meetingId,
      roomId: props.roomId,
      name: props.name,
      repeatability: props.repeatability,
      startDatetime: props.startDatetime,
      endDatetime: props.endDatetime,
    };
  };

  const handleDeleteMeetingOpen = (
    meetingId: string,
    calendarEventId: string,
    enableOwnerDelete: boolean,
  ) => {
    deleteDrawerState.value = {
      status: "DELETE_MEETING_OPEN",
      calendarEventId,
      meetingId,
      enableOwnerDelete,
    };
  };

  const handleDeleteDrawerClose = () => {
    deleteDrawerState.value = { status: "INITIAL" };
  };

  const refreshCalendarEvents = async () => {
    clearAllCalendarEvents();
    clearTodayCalendarEvents();
    await fetchTodayCalendarEvents();
    await fetchCalendarEvents();
  };

  const handleDeleteCalendarEvent = async (id: string) => {
    await useAuthRequest(
      `${runtimeConfig.public.backendApiUrl}/meetings/calendar_events/${id}`,
      { method: "DELETE" },
    ).then(async () => {
      await refreshCalendarEvents();
      handleDeleteDrawerClose();
      handleCalendarEventDetailsClose();
      if (drawerState.value.status === "CALENDAR_EVENT_MORE_OPEN") {
        handleDrawerClose();
      }
    });
  };

  const handleDeleteMeeting = async (id: string) => {
    await useAuthRequest(
      `${runtimeConfig.public.backendApiUrl}/meetings/${id}`,
      {
        method: "DELETE",
      },
    ).then(async () => {
      await refreshCalendarEvents();
      handleDeleteDrawerClose();
      handleCalendarEventDetailsClose();
      if (drawerState.value.status === "CALENDAR_EVENT_MORE_OPEN") {
        handleDrawerClose();
      }
    });
  };

  const handleRejectInvitation = async (
    id: string,
    rejectionType: "meeting" | "calendar_event",
  ) => {
    await useAuthRequest(
      `${runtimeConfig.public.backendApiUrl}/meetings/reject_invitation/${id}`,
      {
        method: "POST",
        body: {
          rejection_type: rejectionType,
        },
      },
    ).then(async () => {
      await refreshCalendarEvents();
      handleDeleteDrawerClose();
      handleCalendarEventDetailsClose();
      if (drawerState.value.status === "CALENDAR_EVENT_MORE_OPEN") {
        handleDrawerClose();
      }
    });
  };

  const handleCreateRoom = async (id: string, currency: CurrencyCode) => {
    // TODO
    try {
      const { data, error } = await useAuthRequest<CreateRoomResponse>(
        `${runtimeConfig.public.backendApiUrl}/rooms`,
        { method: "POST", body: { offer_id: id, currency } },
      );
      if (error.value) {
        Sentry.captureException(error.value);
        return;
      }
      const paymentId = data.value?.payment_id;
      window.location.href = `${runtimeConfig.public.kingspayUrl}${paymentId}`;
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  const handleRenewRoom = async (
    roomId: string,
    offerId: string,
    currency: CurrencyCode,
  ) => {
    try {
      const { data } = await useAuthRequest<CreateRoomResponse>(
        `${runtimeConfig.public.backendApiUrl}/rooms/${roomId}/subscriptions/renew`,
        {
          method: "POST",
          body: { offer_id: offerId, currency },
        },
      );
      const paymentId = data.value?.payment_id;
      window.location.href = `${runtimeConfig.public.kingspayUrl}${paymentId}`;
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  const handleRemoveRoom = async (roomId: string) => {
    await useAuthRequest(
      `${runtimeConfig.public.backendApiUrl}/rooms/${roomId}`,
      {
        method: "DELETE",
      },
    ).then(async () => {
      await fetchRooms();
      if (drawerState.value.status === "ROOM_REMOVE_OPEN") {
        handleDrawerClose();
      }
    });
  };

  const calendarEventsUrl = computed(() => {
    const url = new URL(
      `${runtimeConfig.public.backendApiUrl}/meetings/calendar_events`,
    );
    url.searchParams.set("next_token", allCalendarEvents.value.nextToken);
    if (searchParamsState.allMeetingsFilter) {
      url.searchParams.set("filter", `${searchParamsState.allMeetingsFilter}`);
    }
    return url;
  });

  const fetchCalendarEvents = async () => {
    if (allCalendarEvents.value.status === "LOADING") {
      return;
    }
    setAllCalendarEventsStatus("LOADING");
    return await useAuthRequest<CalendarEventsResponse>(
      calendarEventsUrl.value.href,
    )
      .then((res) => {
        if (res.data.value.calendar_events.length > 0) {
          const calendarEventsArr = transformCalendarEventsResponse(
            res.data.value,
          );
          calendarEventsArr.forEach((calendarEvent) => {
            allCalendarEvents.value.data.set(calendarEvent.id, calendarEvent);
          });
          groupCalendarEventsByDay(calendarEventsArr);
        }
        setAllCalendarEventsNextToken(res.data.value.next_token);
        setAllCalendarEventsStatus("LOADED");
      })
      .catch((err) => {
        Sentry.captureException(err);
        setAllCalendarEventsStatus("LOADED");
      });
  };

  const setAllCalendarEventsStatus = (
    status: AllCalendarEventsState["status"],
  ) => {
    allCalendarEvents.value.status = status;
  };

  const setAllCalendarEventsNextToken = (nextToken: string) => {
    allCalendarEvents.value.nextToken = nextToken;
  };

  const transformCalendarEventsResponse = (
    response: TodayCalendarEventsResponse,
  ) => {
    return response.calendar_events.map((calendarEvent) => {
      const meetingData = response.meetings.find(
        (meeting) => meeting.id === calendarEvent.meeting_id,
      );
      if (!meetingData) {
        // Throw sentry error
        Sentry.captureMessage("Invalid server response");
        throw new Error("Invalid server response");
      }
      const ownerData = response.users_profiles.find(
        (owner) => owner.id === meetingData.owner_id,
      );
      if (!ownerData) {
        // Throw sentry error
        Sentry.captureMessage("Invalid server response");
        throw new Error("Invalid server response");
      }
      return {
        id: calendarEvent.id,
        meetingId: calendarEvent.meeting_id,
        startDateTime: calendarEvent.start_datetime,
        endDateTime: calendarEvent.end_datetime,
        roomPrivateUrl: meetingData.room_private_url,
        roomName: meetingData.room_name,
        roomType: meetingData.room_type,
        name: meetingData.name,
        roomId: meetingData.room_id,
        repeatability: meetingData.repeatability,
        owner: {
          id: ownerData.id,
          name: ownerData.name,
          username: ownerData.username,
          avatarUrl: ownerData.avatar_url,
        },
      };
    });
  };

  const groupCalendarEventsByDay = (
    flatCalendarEvents: FlatCalendarEvent[],
  ) => {
    flatCalendarEvents.forEach((item) => {
      const keyValue = getDateString(item.startDateTime);
      const currArr = allCalendarEvents.value.grouped.has(keyValue)
        ? allCalendarEvents.value.grouped.get(keyValue) || []
        : [];
      currArr.push(item);

      allCalendarEvents.value.grouped.set(keyValue, currArr);
    });
  };

  const clearAllCalendarEvents = () => {
    allCalendarEvents.value.data.clear();
    allCalendarEvents.value.grouped.clear();
    allCalendarEvents.value.nextToken = "";
    allCalendarEvents.value.status = "INITIAL";
  };

  const clearTodayCalendarEvents = () => {
    todayCalendarEvents.value.data.clear();
    todayCalendarEvents.value.status = "INITIAL";
  };

  watch(
    () => searchParamsState.allMeetingsFilter,
    async (newValue, oldValue) => {
      if (newValue === oldValue) {
        return;
      }
      clearAllCalendarEvents();
      await fetchCalendarEvents();
    },
  );

  onMounted(() => {
    setTimeout(async () => {
      await fetchUserProfile();
      await fetchActiveSessions();
      await fetchTodayCalendarEvents();
      await fetchRooms();
      await fetchRoomOfferTypes();
    }, 0);
  });

  return {
    fetchCalendarEvents,
    setAllCalendarEventsFilter,
    searchParamsState,
    mobileActiveTab,
    drawerState,
    deleteDrawerState,
    allCalendarEvents,
    todayCalendarEvents,
    userProfile,
    activeSessions,
    rooms,
    roomOfferTypes,
    roomsWithSessions,
    handleCalendarEventDetailsOpen,
    handleCalendarEventDetailsClose,
    handleCalendarEventDetailsAllParticipantsOpen,
    handleCalendarEventDetailsAllParticipantsClose,
    handleAllCalendarEventsOpen,
    handleAllCalendarEventsClose,
    handleSettingsOpen,
    handleSettingsClose,
    handleRoomSettingsOpen,
    handleRoomRemoveOpen,
    handleAddRoomOpen,
    handleAddRoomChooseRoomType,
    handleAddRoomClose,
    handleAddRoomChooseSubscriptionType,
    handleAddRoomClearSubscriptionType,
    handleChooseRoomOffer,
    handleClearRoomOffer,
    handleCheckoutSubscriptionClose,
    handleDrawerClose,
    handleInstantMeetingOpen,
    handleScheduleOpen,
    handleScheduleWithRoomPickerOpen,
    handleCalendarEventMoreOpen,
    handleEnterRoomOpen,
    handleMeetingInviteUsersOpen,
    handleMeetingEditOpen,
    handleDeleteMeetingOpen,
    handleDeleteDrawerClose,
    handleRenewOpen,
    handleRenewClose,
    fetchUserProfile,
    fetchActiveSessions,
    fetchRooms,
    fetchRoomOfferTypes,
    fetchTodayCalendarEvents,
    handleDeleteMeeting,
    handleDeleteCalendarEvent,
    handleRejectInvitation,
    handleCreateRoom,
    handleRenewRoom,
    handleRemoveRoom,
    refreshCalendarEvents,
  };
});
